<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept 
            :isFirstValue="false"
            type="search" 
            label="부서" 
            name="seniorDeptCd" 
            v-model="searchParam.seniorDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field 
            type="user" 
            label="선임자" 
            name="seniorUserId" 
            v-model="searchParam.seniorUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="dismissalFlagItems"
            type="search"
            label="선/해임 구분"
            itemText="codeName"
            itemValue="code"
            name="dismissalFlag"
            v-model="searchParam.dismissalFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="seniorDismissal"
      title="선해임 현황 목록"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPopup" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'senior-dismissal-status',
  data() {
    return {
      searchParam: {
        plantCd: '',
        seniorDeptCd: '',
        seniorUserId: '',
        dismissalFlag: 'N',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            label: '관련법규',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'seniorName',
            field: 'seniorName',
            label: '선임명',
            align: 'left',
            style: 'width:200px',
            type: "link",
            sortable: true,
          },
          {
            name: 'seniorDeptName',
            field: 'seniorDeptName',
            label: '부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'seniorUserName',
            field: 'seniorUserName',
            label: '선임자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'seniorDate',
            field: 'seniorDate',
            label: '선임일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'dismissalFlagName',
            field: 'dismissalFlagName',
            label: '선/해임',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: "dismissalDate",
            field: 'dismissalDate',
            label: '해임일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          }
        ],
        data: [],
      },
      dismissalFlagItems: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        isFull: true,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.dismissalFlagItems = [
        { code: 'N', codeName: this.$language('선임') },
        { code: 'Y', codeName: this.$language('해임') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.senior.status.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPopup(row);
    },
    openPopup(result) {
      this.popupOptions.title = '선임 현황 상세';
      this.popupOptions.param = {
        seniorStatusId: result ? result.seniorStatusId : '',
      };
      this.popupOptions.target = () => import(`${"./seniorDismissalStatusDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
